import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { storageKeys } from "../../constants/storage.constants";
import { setAuthUser } from "../../features/Auth Slice/authSlice";
import { getUserData, updateProfileDate } from "../../services/profile.service";
import { RootState, store } from "../../store/store";
import {
  IAdminForm,
  IUserProfile,
} from "../../types/interfaces/IUserProfile.interface";

const relationOptions = [
  { value: "Parent", label: "Parent" },
  { value: "Sibling", label: "Sibling" },
  { value: "Other", label: "Other" },
];
const maritalStatusOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
];

const AdminProfile = () => {
  const navigation = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>();
  const [martiaStatus, setMartialStatus] = useState<string>();
  const [dateOfBirth, setDateofBirth] = useState<string>("");
  const [confirmationDate, setConfirmationDate] = useState<string>("");
  const [endServiceDate, setEndServiceDate] = useState<string>("");

  const [userData, setUserData] = useState<IUserProfile>();
  const { User } = useSelector((state: RootState) => state?.auth);
  const [updateData, setUpdateData] = useState<IAdminForm>();
  const [originalData, setOriginalData] = useState<IAdminForm>();
  const [contactNumberError, setContactNumberError] = useState<boolean>(false);
  const [emergencyContactNoError, setEmergencyContactNoError] = useState<boolean>(false);
  const endOfServiceRef: any = useRef<ReactDatePicker>()
  const confirmationDateRef: any = useRef<ReactDatePicker>()

  const getAdminData = async () => {
    const res = await getUserData(User?.email as string);
    if (res?.res) {
      const userProfile = res.res;
      const userDetails = userProfile.UserDetails.PersonalInfo;
      const emergencyContact = userProfile.UserDetails.EmergencyContact;

      const data = {
        fullName: userDetails.FullName,
        contactNumber: userDetails.ContactNumber,
        residentialAddress: userDetails.ResidentialAddress,
        dateOfBirth: userDetails.DateOfBirth,
        emergencyContactName: emergencyContact.ContactName,
        emergencyContactNo: emergencyContact.ContactNumber,
      };

      setUserData(userProfile);
      setUpdateData(data);
      setOriginalData(data);

      setConfirmationDate(userProfile.ConfirmationDate || "");
      setEndServiceDate(userProfile.EndOfServiceDate || "");
      setDateofBirth(userDetails.DateOfBirth || "");
      setMartialStatus(userDetails.MaritalStatus);
      setSelectedOption(emergencyContact.Relation);
    }
  };

  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^(?:\+92|0)3\d{9}$/;
    return phoneRegex.test(number);
  };

  const inputChangeHandler = (event: any) => {
    const { name, value } = event.target;
    if (name === "contactNumber") {
      setContactNumberError(!validatePhoneNumber(value));
    } else if (name === "emergencyContactNo") {
      setEmergencyContactNoError(!validatePhoneNumber(value));
    }

    setUpdateData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const maritalStatusHandler = (value: string) => {
    setMartialStatus(value);
  };

  const updateAdminProfile = async () => {
    if (
      !updateData?.fullName ||
      !updateData?.contactNumber ||
      !updateData?.residentialAddress ||
      !updateData?.dateOfBirth ||
      !updateData?.emergencyContactName ||
      !updateData?.emergencyContactNo ||
      !martiaStatus ||
      !selectedOption ||
      !dateOfBirth ||
      !confirmationDate ||
      !endServiceDate
    ) {
      toast.info("Please fill all required fields.");
      return;
    }

    if (contactNumberError || emergencyContactNoError) {
      toast.error("Please correct the errors before submitting.");
      return;
    }

    if (updateData && martiaStatus && selectedOption && User) {
      const res = await updateProfileDate(
        updateData,
        martiaStatus,
        selectedOption,
        dateOfBirth,
        confirmationDate,
        endServiceDate,
        User?.id
      );
      if (res?.res) {
        await getAdminData()
        const tokenBase64: any = res.res;
        const tokenData: any = jwtDecode(tokenBase64);
        if (localStorage.getItem(storageKeys?.rememberMe) === 'true')
          localStorage.setItem(storageKeys?.user, JSON.stringify(tokenData));
        else localStorage.setItem(storageKeys?.user, JSON.stringify(tokenData));
        store.dispatch(setAuthUser(tokenData));
        localStorage.setItem(storageKeys?.acToken, tokenData);
      }

    } else {
      toast.info("Please fill all required fields.");
    }
  };

  const discardChanges = () => {
    setUpdateData(originalData);
    setDateofBirth(originalData?.dateOfBirth || "");
    setConfirmationDate(userData?.ConfirmationDate || "");
    setEndServiceDate(userData?.EndOfServiceDate || "");
    setMartialStatus(userData?.UserDetails.PersonalInfo.MaritalStatus);
    setSelectedOption(userData?.UserDetails.EmergencyContact.Relation);
    setContactNumberError(false);
    setEmergencyContactNoError(false);
  };

  useEffect(() => {
    if (!User?.email) return;
    getAdminData();
  }, [User]);

  return (
    <>
      <div className="adminProfile">
        <div className="flex items-center mx-2  py-5">

          <div>
            <h3 className="text-xl sm:text-2xl font-semibold text-[#33475F]">
              Personal Profile
            </h3>
            {/* <p className="text-sm text-[#33475F]">Personal Profile</p> */}
          </div>
        </div>
        <div className="border-[#EBEBEB] border rounded-2xl p-3 bg-white">
          <p className="text-base text-[#333333s] mb-5">Personal Info</p>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="basis-full sm:basis-1/2 mb-5">
              <input
                type="text"
                name="fullName"
                id="fullName"
                value={updateData?.fullName}
                onChange={inputChangeHandler}
                autoComplete="given-name"
                className="block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Full Name"
              />
            </div>
            <div className="basis-full sm:basis-1/2 mb-5">
              <input
                type="tel"
                name="contactNumber"
                value={updateData?.contactNumber}
                onChange={inputChangeHandler}
                className={`block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${contactNumberError ? 'ring-red-500' : 'ring-gray-300'} placeholder:text-[#858585] focus:ring-1 focus:ring-inset ${contactNumberError ? 'focus:ring-red-500' : 'focus:ring-indigo-600'} sm:text-sm sm:leading-6`}
                placeholder="Contact Number"
              />
              {contactNumberError && (
                <p className="text-red-500 text-sm mt-1">Invalid contact number</p>
              )}
            </div>
          </div>
          <div className="basis-full mb-5">
            <input
              type="text"
              name="residentialAddress"
              id="residentialAddress"
              value={updateData?.residentialAddress}
              onChange={inputChangeHandler}
              autoComplete="given-name"
              className="block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Home address"
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="basis-full sm:basis-1/2 mb-5 relative">
              <Select
                value={
                  userData
                    ? {
                      value: martiaStatus,
                      label: martiaStatus,
                    }
                    : null
                }
                options={maritalStatusOptions}
                placeholder="Marital Status"
                onChange={(value) =>
                  value && maritalStatusHandler(value?.value || "")
                }
              />
            </div>
            <div className="basis-full sm:basis-1/2 mb-5 relative">
              <ReactDatePicker
                className={`block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                onChange={(date: any) => {
                  setDateofBirth(moment(date).format("DD/MM/YYYY"));
                }}
                value={dateOfBirth}
                placeholderText="Date of birth"
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                preventOpenOnFocus
              />
            </div>
          </div>
          <div className="flex items-center justify-between mb-5">
            <p className="text-base text-[#333333s]">Emergency Contact Info</p>
          </div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="basis-full sm:basis-1/3 mb-5">
              <input
                type="text"
                name="emergencyContactName"
                value={updateData?.emergencyContactName}
                onChange={inputChangeHandler}
                className="block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Contact Name"
              />
            </div>
            <div className="basis-full sm:basis-1/3 mb-5">
              <input
                type="tel"
                name="emergencyContactNo"
                value={updateData?.emergencyContactNo}
                onChange={inputChangeHandler}
                className={`block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${emergencyContactNoError ? 'ring-red-500' : 'ring-gray-300'} placeholder:text-[#858585] focus:ring-1 focus:ring-inset ${emergencyContactNoError ? 'focus:ring-red-500' : 'focus:ring-indigo-600'} sm:text-sm sm:leading-6`}
                placeholder="Contact Number"
              />
              {emergencyContactNoError && (
                <p className="text-red-500 text-sm mt-1">Invalid emergency contact number</p>
              )}
            </div>
            <div className="basis-full sm:basis-1/3 mb-5">
              <Select
                value={
                  userData
                    ? {
                      value: selectedOption,
                      label: selectedOption,
                    }
                    : null
                }
                options={relationOptions}
                placeholder="Relation"
                onChange={(value) => value && setSelectedOption(value?.value)}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="basis-full sm:basis-1/2 relative">
              <p className="text-base text-[#333333] mb-5">Confirmation Date</p>
              <ReactDatePicker
                ref={confirmationDateRef}
                className={`block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                onChange={(date: any) => {
                  setConfirmationDate(moment(date).format("DD/MM/YYYY"));
                }}
                value={confirmationDate}
                placeholderText="Confirmation Date"
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name="ConfirmationDate"
                preventOpenOnFocus
              />
              <span className="absolute top-[55px] right-5 cursor-pointer" onClick={() => confirmationDateRef.current.setOpen(true)}>
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            </div>
            <div className="basis-full sm:basis-1/2 relative">
              <p className="text-base text-[#333333] mb-5">
                End of Service Date
              </p>
              <ReactDatePicker
                ref={endOfServiceRef}
                className={`block w-full bg-[#FAFAFA] rounded-full placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                onChange={(date: any) => {
                  setEndServiceDate(moment(date).format("DD/MM/YYYY"));
                }}
                value={endServiceDate}
                placeholderText="End of Service Date"
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                preventOpenOnFocus
              />
              <span className="absolute top-[55px] right-5 cursor-pointer" onClick={() => endOfServiceRef.current.setOpen(true)}>
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-end pt-4">
            <button
              className="text-sm font-semibold text-[#378EB5] border border-[#378EB5] rounded-lg px-4 py-2.5 hover:bg-[#0891B2] hover:text-white"
              onClick={discardChanges}
            >
              Discard
            </button>
            <button
              className="bg-[#0891B2] text-[#FAFAFA] text-sm px-4 py-2.5 rounded-lg font-semibold hover:bg-[#5a9dad]"
              onClick={updateAdminProfile}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProfile;

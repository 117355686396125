import {
  ExclamationCircleIcon
} from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MdOutlineWorkHistory } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  addRemoveEmployee,
  getAllBankData,
  updateEmployee
} from "../../services/employeeManagement.service";
import { addUserPerk, deleteUserPerk, getPerkHistory, IAddPerk } from "../../services/perk.service";
import {
  IAlertFields,
  IAlertSection,
  IEmployeeFormData,
  IEmployeePayload,
  User
} from "../../types/interfaces/IEmployeesManagement.interface";
import { formatDateSlash } from "../../utils/dateFromatter";
import { scrollToTop } from "../../utils/tableScroller";
import WorkHistory from "./WorkHistory";



const initialEmpState = {
  name: "",
  email: "",
  password: "",
  designation: "",
  imageURL: "",
  active: false,
  role: "",
  joiningDate: "",
  confirmationDate: "",
  endOfServiceDate: "",
  userID: 0,
  fullName: "",
  dateOfBirth: "",
  residentialAddress: "",
  contactNumber: "",
  maritalStatus: "",
  emergencyContactNumber: "",
  emergencyContactName: "",
  emergencyContactRelation: "",
  bankName: "",
  bankCode: "",
  accountTitle: "",
  accountNumber: null,
  salaryAmount: null,
  fuelAllowance: 0,
  bonusPerAnnum: null,
  gratuity: null,
  gender: "",
  fatherName: "",
  cnic: ""
};
const initialAlertFields = {
  fullName: false,
  contactNumber: false,
  residentialAddress: false,
  designation: false,
  maritalStatus: false,
  dateOfBirth: false,
  bankName: false,
  accountTitle: false,
  accountNumber: false,
  email: false,
  password: false,
  gender: false,
  fatherName: false,
  cnic: false
};

const initialAlertSection = {
  personalInfoCheck: false,
  bankAccountInfoCheck: false,
  loginInfo: false,
};
const designationOptions = [
  { value: "Developer", label: "Developer" },
  { value: "Tester", label: "Tester" },
  { value: "UI/UX", label: "UI/UX" },
  { value: "Designer", label: "Designer" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Team Lead", label: "Team Lead" },
  { value: "Other", label: "Other" },
];
const maritalStatusOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
];
const relationOptions = [
  { value: "Parent", label: "Parent" },
  { value: "Sibling", label: "Sibling" },
  { value: "Other", label: "Other" },
];
const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];


interface Props {
  selectedEmployee: User;
  setSelectedEmployee: Function;
  getAllEmployees: () => void;
}
const EmployeeInformation: React.FC<Props> = ({
  selectedEmployee,
  setSelectedEmployee,
  getAllEmployees,
}) => {
  const [empFormData, setEmpFormData] = useState<IEmployeeFormData>({
    ...initialEmpState,
  });
  const [empFormDataBackup, setEmpFormDataBackup] = useState<IEmployeeFormData>(
    { ...initialEmpState }
  );
  const [bankData, setBankData] = useState<
    { value: string; label: string }[] | null
  >(null);

  const [isAddingEmp, setIsAddingEmp] = useState<boolean>(false);
  const [isEditingEmp, setIsEditingEmp] = useState<boolean>(false);
  const [alertCheckFields, setAlertCheckFields] =
    useState<IAlertFields>(initialAlertFields);
  const [alertCheckSection, setAlertCheckSection] =
    useState<IAlertSection>(initialAlertSection);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [contactNumberError, setContactNumberError] = useState<string>("");
  const [emergencyContactNumberError, setEmergencyContactNumberError] =
    useState<string>("");
  const [otherDesignation, setOtherDesignation] = useState<string>("");
  const [workHistoryData, setWorkHistoryData] = useState<IAddPerk[]>([])
  const [dob, setDob] = useState<any>(empFormData?.dateOfBirth);

  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const handleAddEmp = () => {
    setIsAddingEmp(true);
    setIsEditingEmp(false);
  };
  console.log("Selected", selectedEmployee);
  const handleEditEmp = () => {
    setIsAddingEmp(false);
    setIsEditingEmp(true);
  };

  const handleDiscard = () => {
    setEmpFormData((prev) => ({ ...prev, ...empFormDataBackup }))
    setIsEditingEmp(false);
  };

  const handleCancelEditing = () => {
    setIsAddingEmp(false);
    setIsEditingEmp(false);

  };

  const handleAddRemoveEmployee = async (
    userID: number,
    statusToSet: string
  ) => {
    const reqBody = { userID: userID, statusToSet: statusToSet };
    const res = await addRemoveEmployee(reqBody);
    if (res?.res) {
      handleCancelEditing();
      getAllEmployees();
      if (statusToSet === "deactivate") {
        setSelectedEmployee(null)
      }
      else {
        setSelectedEmployee((prevEmployee: any) => {
          if (prevEmployee) {
            return {
              ...prevEmployee,
              Active: !prevEmployee.Active, // Toggle the Active property
            };
          }
          return prevEmployee; // If prevEmployee is undefined, return it as is
        });

      }


    }
  };



  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: number | string
  ) => {
    const name = event?.target?.name;

    if (name === "contactNumber" || name === "emergencyContactNumber") {
      const regex = /^(03[0-9]{9}|923[0-9]{9})$/;
      if (!regex.test(event.target.value)) {
        const errorMsg = "Invalid contact number format.";
        if (name === "contactNumber") {
          setContactNumberError(errorMsg);
        } else {
          setEmergencyContactNumberError(errorMsg);
        }
        setEmpFormData((prev) => ({ ...prev, [name]: event.target.value }));
        return;
      } else {
        if (name === "contactNumber") {
          setContactNumberError("");
        } else {
          setEmergencyContactNumberError("");
        }
      }
    }

    if (name === "salaryAmount" || name === "fuelAllowance") {
      setEmpFormData((prev) => ({
        ...prev,
        [name]: value !== "" ? (value as number) : "",
      }));
      return;
    }

    setEmpFormData((prev) => ({ ...prev, [name]: event.target.value }));
  };

  const handleDropdownSelect = (val: string, name: string) => {
    setEmpFormData((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateSelect = (date: Date | null, name: string) => {
    setEmpFormData((prev) => ({
      ...prev,
      [name]: date ? formatDateSlash(date) : "",
    }));
  };




  const validateFields = () => {
    const requiredFields = [
      "fullName",
      "contactNumber",
      "residentialAddress",
      "designation",
      "maritalStatus",
      "dateOfBirth",
      "bankName",
      "accountTitle",
      "accountNumber",
      "fatherName",
      "gender",
      "cnic"
    ];

    for (let field of requiredFields) {
      if (!empFormData[field as keyof IEmployeeFormData]) {
        return false;
      }
    }
    if (empFormData.designation === "") {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      toast.error("Please fill out all required fields.");
      return;
    }

    if (contactNumberError || emergencyContactNumberError) {
      toast.error(contactNumberError || emergencyContactNumberError);
      return;
    }

    if (empFormData.fuelAllowance < 0) {
      toast.error("Fuel Allowance cannot be neagtive");

      return;
    }

    const reqBody: IEmployeePayload = {
      email: empFormData?.email,
      password: empFormData?.password,
      designation: empFormData?.designation,
      joiningDate: empFormData?.joiningDate,
      confirmationDate: empFormData?.confirmationDate,
      endOfServiceDate: empFormData?.endOfServiceDate,
      fullName: empFormData?.fullName,
      dateOfBirth: empFormData?.dateOfBirth,
      residentialAddress: empFormData?.residentialAddress,
      contactNumber: empFormData?.contactNumber,
      maritalStatus: empFormData?.maritalStatus,
      emergencyContactNumber: empFormData?.emergencyContactNumber,
      emergencyContactName: empFormData?.emergencyContactName,
      emergencyContactRelation: empFormData?.emergencyContactRelation,
      bankName: empFormData?.bankName,
      accountTitle: empFormData?.accountTitle,
      accountNumber: empFormData?.accountNumber,
      salaryAmount: empFormData?.salaryAmount as number,
      bankCode: empFormData.bankCode,
      fuelAllowance: empFormData.fuelAllowance as number,
      fatherName: empFormData.fatherName,
      gender: empFormData.gender,
      cnic: empFormData.cnic,
    };
    const res = await updateEmployee(empFormData?.userID.toString(), reqBody);
    if (res?.res) {
      getAllEmployees();
      handleCancelEditing();
      toast.success("Submitted Successfully");
    }
  };

  const handleAlert = (sectionName?: string, fieldName?: string) => {
    const {
      fullName,
      contactNumber,
      residentialAddress,
      designation,
      maritalStatus,
      dateOfBirth,
      bankName,
      accountTitle,
      accountNumber,
      email,
      password,
      gender,
      fatherName,
      cnic,
    } = empFormData;
    let anyAlert = false;

    if (
      fullName === "" ||
      contactNumber === "" ||
      residentialAddress === "" ||
      designation === "" ||
      maritalStatus === "" ||
      dateOfBirth === "" ||
      designation === "" ||
      gender === "" ||
      fatherName === '' ||
      cnic === ""

    ) {
      setAlertCheckSection((prev) => ({ ...prev, personalInfoCheck: true }));
      anyAlert = true;
    } else
      setAlertCheckSection((prev) => ({ ...prev, personalInfoCheck: false }));

    if (bankName === "" || accountTitle === "" || !accountNumber) {
      setAlertCheckSection((prev) => ({
        ...prev,
        bankAccountInfoCheck: true,
      }));
      anyAlert = true;
    } else
      setAlertCheckSection((prev) => ({
        ...prev,
        bankAccountInfoCheck: false,
      }));

    setIsDisabled(anyAlert);
  };

  const fetchPerkHistory = async () => {
    try {
      const res = await getPerkHistory(selectedEmployee?.ID)
      setWorkHistoryData(res as IAddPerk[])
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (!selectedEmployee?.ID) return;
    if (bankData) {
      const bankLabel = bankData
        .filter(
          (item) =>
            item.label === selectedEmployee?.UserDetails?.BankDetails?.BankName
        )
        .map((item) => item.value)[0];
      console.log(selectedEmployee, "heheheh")
      const temp = {
        name: selectedEmployee?.Name ?? "",
        email: selectedEmployee?.Email ?? "",
        password: selectedEmployee?.Password ?? "",
        designation: selectedEmployee?.Designation ?? "",
        gender: selectedEmployee?.UserDetails?.PersonalInfo.Gender ?? "",
        fatherName: selectedEmployee?.UserDetails?.PersonalInfo.FatherName ?? "",
        cnic: selectedEmployee?.UserDetails?.PersonalInfo.CNIC
          ?? "",
        imageURL: selectedEmployee?.ImageURL ?? "",
        active: selectedEmployee?.Active ?? false,
        role: selectedEmployee?.Role ?? "",
        joiningDate: selectedEmployee?.JoiningDate ?? "",
        confirmationDate: selectedEmployee?.ConfirmationDate ?? "",
        endOfServiceDate: selectedEmployee?.EndOfServiceDate ?? "",
        userID: (selectedEmployee?.ID as unknown as number) ?? 0,
        fullName: selectedEmployee?.UserDetails?.PersonalInfo?.FullName ?? "",
        dateOfBirth:
          selectedEmployee?.UserDetails?.PersonalInfo?.DateOfBirth ?? "",
        residentialAddress:
          selectedEmployee?.UserDetails?.PersonalInfo?.ResidentialAddress ?? "",
        contactNumber:
          selectedEmployee?.UserDetails?.PersonalInfo?.ContactNumber ?? "",
        maritalStatus:
          selectedEmployee?.UserDetails?.PersonalInfo?.MaritalStatus ?? "",
        emergencyContactNumber:
          selectedEmployee?.UserDetails?.EmergencyContact?.ContactNumber ?? "",
        emergencyContactName:
          selectedEmployee?.UserDetails?.EmergencyContact?.ContactName ?? "",
        emergencyContactRelation:
          selectedEmployee?.UserDetails?.EmergencyContact?.Relation ?? "",
        bankName: selectedEmployee?.UserDetails?.BankDetails?.BankName ?? "",
        accountTitle:
          selectedEmployee?.UserDetails?.BankDetails?.AccountTitle ?? "",
        accountNumber:
          selectedEmployee?.UserDetails?.BankDetails?.AccountNumber ?? 0,
        salaryAmount: selectedEmployee?.UserDetails?.SalaryDetails?.Amount ?? 0,
        fuelAllowance: selectedEmployee.UserDetails.Allowances.FuelAllowance,
        bonusPerAnnum: 0,
        gratuity: 0,
        bankCode: bankLabel,


      };
      setEmpFormData({ ...temp });
      setEmpFormDataBackup({ ...temp });
      setIsAddingEmp(false);
      setIsEditingEmp(false);
      scrollToTop(scrollToTopRef);
    }
    fetchPerkHistory()
  }, [selectedEmployee]);

  useEffect(() => {
    handleAlert();
  }, [empFormData, otherDesignation]);

  useEffect(() => {
    getBankData();


  }, []);

  const addPerk = async (data: IAddPerk) => {
    const res: any = await addUserPerk(data)
    if (res.error) {
      toast.error(res?.error)

    }
    if (res) {
      fetchPerkHistory()
      toast.success("Perk Added Successfully")
    }
  }

  const deletePerk = async (id: string) => {
    const res: any = await deleteUserPerk(selectedEmployee.ID, id)
    if (res.error) {
      toast.error(res?.error)

    }
    if (res) {
      fetchPerkHistory()
      toast.success("Perk Deleted Successfully")
    }
  }


  const getBankData = async () => {
    const res = await getAllBankData();
    if (res && res.res) {
      const transformedData = res.res.bankDetails.map((item) => ({
        value: item.BankCode,
        label: item.BankName,
      }));
      setBankData(transformedData);
    }
  };

  const isFieldEmpty = (field: string) => {
    if (field === "otherDesignation") {
      return empFormData.designation === "Other" && !otherDesignation;
    }

    if (field === "fuelAllowance") {
      return (
        empFormData.fuelAllowance === undefined ||
        empFormData.fuelAllowance === null
      );
    }

    return !empFormData[field as keyof IEmployeeFormData];
  };

  const parseDateString = (dateString: string): Date | null => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  };

  const handleKeyDown = (e: any) => {
    e.preventDefault(); // Prevent all key presses
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true); // Set to `true` to open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Set to `false` to close the modal
  };


  return (
    <>
      <div className="mb-4">
        <div
          className={`flex items-center ${!selectedEmployee?.ID ? "justify-end" : "justify-between"
            } mb-4 h-10`}
        >
          <div className="flex-1">
            {selectedEmployee?.ID && (
              <h2 className="text-xl text-[#333333] font-semibold">
                Employee Info
              </h2>
            )}
          </div>
          {selectedEmployee?.ID ? (
            <div className="flex items-center justify-end gap-2">
              <button
                className="btnApprove min-w-auto w-auto flex items-center gap-2 hover:bg-[#5a9dad]"
                onClick={openModal}
              >
                <MdOutlineWorkHistory className="text-white w-4 h-4" /> Perk History
              </button>
            </div>
          ) : (
            <>
            </>
          )}
        </div>
        <div
          className="Employees border-[#EBEBEB] border rounded-2xl p-3 bg-white mb-5 overflow-y-scroll h-[calc(100vh-390px)]"
          ref={scrollToTopRef}
        >
          {selectedEmployee?.ID && selectedEmployee.Active ? (
            <>
              <div className="flex items-center justify-between mb-5">
                <p className="text-base text-[#333333s]">
                  Personal Info<span className="text-red-600">*</span>
                </p>
                {alertCheckSection?.personalInfoCheck && (
                  <span>
                    <ExclamationCircleIcon className="text-[#962D2D] w-5 h-5" />
                  </span>
                )}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-1 mb-2 sm:mb-5">
                <div>
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    autoComplete="given-name"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("fullName")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                      
                     `}
                    placeholder="Full Name"
                    value={empFormData?.fullName}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    onBlur={() => handleAlert("personalInfo", "fullName")}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="contactNumber"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("contactNumber")
                      ? "ring-red-500"
                      : contactNumberError
                        ? "ring-red-500"
                        : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                     
                    `}
                    placeholder="Contact Number"
                    value={empFormData?.contactNumber}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() => handleAlert("personalInfo", "contactNumber")}
                  />
                  {contactNumberError && (
                    <p className="text-red-500 text-xs">{contactNumberError}</p>
                  )}
                </div>
              </div>
              <div className="basis-full mb-2 sm:mb-5">
                <input
                  type="text"
                  name="residentialAddress"
                  id="residentialAddress"
                  autoComplete="given-name"
                  className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("residentialAddress")
                    ? "ring-red-500"
                    : "ring-gray-300"
                    } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                  placeholder="Home address"
                  value={empFormData?.residentialAddress}
                  onChange={(e) => handleInputChange(e)}
                  onBlur={() =>
                    handleAlert("personalInfo", "residentialAddress")
                  }
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-1 mb-2 sm:mb-5">
                <div
                  className={`relative ${isFieldEmpty("designation") ? "ring-red-500" : ""
                    }`}
                >
                  <Select
                    placeholder="Designation"
                    name="designation"
                    options={designationOptions}
                    value={designationOptions.find(
                      (opt) => opt.value === empFormData?.designation
                    )}
                    onChange={(selectedOption) => {
                      const value = selectedOption?.value as string;
                      if (value === "Other") {
                        handleDropdownSelect(value, "designation");
                        setOtherDesignation(""); // Clear any previous other designation
                      } else {
                        handleDropdownSelect(value, "designation");
                      }
                    }}
                    onBlur={() => handleAlert("personalInfo", "designation")}
                    className={`
                    rounded-lg focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500
                    ${isFieldEmpty("designation")
                        ? "border border-red-500"
                        : "ring-gray-300"
                      } `}
                  />
                </div>
                <div
                  className={`relative ${isFieldEmpty("maritalStatus") ? "ring-red-500" : ""
                    }`}
                >
                  <Select
                    placeholder="Marital Status"
                    name="maritalStatus"
                    options={maritalStatusOptions}
                    value={maritalStatusOptions.find(
                      (opt) => opt.value === empFormData?.maritalStatus
                    )}
                    onChange={(selectedOption) => {
                      handleDropdownSelect(
                        selectedOption?.value as string,
                        "maritalStatus"
                      );
                    }}
                    onBlur={() => handleAlert("personalInfo", "maritalStatus")}
                    className={`
                    rounded-lg focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500
                    ${isFieldEmpty("maritalStatus")
                        ? "border border-red-500"
                        : "ring-gray-300"
                      }`}
                  />
                </div>
                <div
                  className={`relative ${isFieldEmpty("dateOfBirth") ? "ring-red-500" : ""
                    }`}
                >
                  <ReactDatePicker
                    className={`cursor-pointer block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("dateOfBirth") ? "ring-red-500" : "ring-gray-300"
                      } placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    onChange={(date) => {
                      handleDateSelect(date, "dateOfBirth");
                    }}
                    maxDate={new Date()}
                    selected={parseDateString(empFormData?.dateOfBirth)}
                    placeholderText="Date of birth"
                    dateFormat="dd/MM/yyyy"
                    onBlur={() => handleAlert("personalInfo", "dateOfBirth")}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    preventOpenOnFocus
                    onKeyDown={(e) => handleKeyDown(e)}

                  />

                </div>

                <span
                  className={`absolute top-3 right-5 pointer-events-none `}
                >
                  <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                </span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-1 mb-2 sm:mb-5" >

                <div className="basis-full mb-2 sm:mb-5">
                  <input
                    type="text"
                    name="fatherName"
                    id="fatherName"
                    autoComplete="given-name"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("fatherName")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Father Name"
                    value={empFormData?.fatherName

                    }
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() =>
                      handleAlert("personalInfo", "fatherName")
                    }
                  />
                </div>
                <div className="basis-full mb-2 sm:mb-5">
                  <input
                    type="text"
                    name="cnic"
                    id="cnic"
                    autoComplete="given-name"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("cnic")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="CNIC"
                    value={empFormData?.cnic}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() =>
                      handleAlert("personalInfo", "cnic")
                    }
                  />
                </div>
                <div
                  className={`relative ${isFieldEmpty("maritalStatus") ? "ring-red-500" : ""
                    }`}
                >
                  <Select
                    placeholder="Gender"
                    name="gender"
                    options={genderOptions}
                    value={genderOptions.find(
                      (opt) => opt.value === empFormData?.gender
                    )}
                    onChange={(selectedOption) => {
                      handleDropdownSelect(
                        selectedOption?.value as string,
                        "gender"
                      );
                    }}
                    onBlur={() => handleAlert("personalInfo", "gender")}
                    className={`
rounded-lg focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500
${isFieldEmpty("gender")
                        ? "border border-red-500"
                        : "ring-gray-300"
                      }`}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between mb-5">
                <p className={`text-base text-[#333333s]`}>
                  {/* ${empFormData?.designation === "Other" ? "mt-10" : ""} */}
                  Bank Account Info<span className="text-red-600">*</span>
                </p>
                {alertCheckSection?.bankAccountInfoCheck && (
                  <span>
                    <ExclamationCircleIcon className="text-[#962D2D] w-5 h-5" />
                  </span>
                )}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-1 mb-2 sm:mb-5">
                <div>
                  {bankData && (
                    <Select
                      placeholder="Bank Name"
                      options={bankData}
                      value={bankData.find(
                        (opt) => opt.label === empFormData?.bankName
                      )}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setEmpFormData((prev) => ({
                            ...prev,
                            bankName: selectedOption.label,
                            bankCode: selectedOption.value,
                          }));
                        }
                      }}
                      className={`
                      rounded-lg focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500
                      ${isFieldEmpty("bankName")
                          ? "border border-red-500"
                          : "ring-gray-300"
                        }`}
                    />
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    name="accountTitle"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("accountTitle")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Acc. title"
                    value={empFormData?.accountTitle}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() => handleAlert("bankInfo")}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="accountNumber"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("accountNumber")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Acc. number"
                    value={empFormData?.accountNumber as number}
                    onChange={(e) => handleInputChange(e, +e?.target?.value)}
                    onBlur={() => handleAlert("bankInfo")}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between mb-5">
                <p className="text-base text-[#333333s]">
                  Emergency Contact Info
                </p>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-1 mb-2 sm:mb-5">
                <div>
                  <input
                    type="text"
                    name="emergencyContactName"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("emergencyContactName")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Contact Name"
                    value={empFormData?.emergencyContactName}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="emergencyContactNumber"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("emergencyContactNumber")
                      ? "ring-red-500"
                      : emergencyContactNumberError
                        ? "ring-red-500"
                        : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Contact Number"
                    value={empFormData?.emergencyContactNumber}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() =>
                      handleAlert(
                        "emergencyContactInfo",
                        "emergencyContactNumber"
                      )
                    }
                  />
                  {emergencyContactNumberError && (
                    <p className="text-red-500 text-xs">
                      {emergencyContactNumberError}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <Select
                    placeholder="Relation"
                    options={relationOptions}
                    name="emergencyContactRelation"
                    value={relationOptions.find(
                      (opt) =>
                        opt.value === empFormData?.emergencyContactRelation
                    )}
                    onChange={(selectedOption) => {
                      handleDropdownSelect(
                        selectedOption?.value as string,
                        "emergencyContactRelation"
                      );
                    }}
                    className={`
                    rounded-lg focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500
                    ${isFieldEmpty("emergencyContactRelation")
                        ? "border border-red-500"
                        : "ring-gray-300"
                      }`}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-1 mb-2 sm:mb-5">
                <div>
                  <p className="text-base text-[#333333] mb-5">
                    Salary Package
                  </p>
                  <input
                    type="number"
                    name="salaryAmount"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("salaryAmount")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Salary amount"
                    value={
                      empFormData?.salaryAmount === 0
                        ? ""
                        : empFormData?.salaryAmount || ""
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace(/^0+(?=\d)/, "");
                      handleInputChange(e, value ? parseInt(value) : 0);
                    }}
                  />
                </div>
                <div>
                  <p className="text-base text-[#333333] mb-5">
                    Fuel Allowance
                  </p>
                  <input
                    type="number"
                    name="fuelAllowance"
                    className={`block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("fuelAllowance")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#858585] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    placeholder="Fuel Allowance"
                    value={
                      empFormData?.fuelAllowance !== undefined
                        ? empFormData.fuelAllowance
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      handleInputChange(
                        e,
                        value !== "" ? parseInt(value, 10) : ""
                      );
                    }}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        handleInputChange(e, 0);
                      }
                    }}
                    min={0}
                  />
                </div>
                <div className="relative">
                  <p className="text-base text-[#333333] mb-5">Joining Date</p>
                  <ReactDatePicker
                    className={`cursor-pointer block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("joiningDate")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    onChange={(date) => {
                      handleDateSelect(
                        date,
                        "joiningDate"
                      );
                    }}
                    selected={parseDateString(empFormData?.joiningDate)}

                    value={empFormData?.joiningDate}
                    placeholderText="Joining Date"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    preventOpenOnFocus
                    onKeyDown={(e) => handleKeyDown(e)}

                  />
                  <span
                    className={`absolute top-[55px] right-5 pointer-events-none `}
                  >
                    <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-1 mb-2 sm:mb-5">
                <div className="relative">
                  <p className="text-base text-[#333333] mb-5">
                    Confirmation Date
                  </p>
                  <ReactDatePicker
                    className={`cursor-pointer block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("confirmationDate")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    onChange={(date) => {
                      handleDateSelect(
                        date,
                        "confirmationDate"
                      );
                    }}
                    selected={parseDateString(empFormData?.confirmationDate)}
                    value={empFormData?.confirmationDate}
                    placeholderText="Confirmation Date"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    preventOpenOnFocus
                    onKeyDown={(e) => handleKeyDown(e)}

                  />
                  <span
                    className={`absolute top-[55px] right-5 pointer-events-none `}
                  >
                    <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                  </span>
                </div>
                <div className="relative">
                  <p className="text-base text-[#333333] mb-5">
                    End of Service Date
                  </p>
                  <ReactDatePicker
                    className={`cursor-pointer block w-full bg-[#00000] rounded-lg placeholder:text-base border-0 py-2.5 px-5 text-gray-900 shadow-sm ring-1 ring-inset ${isFieldEmpty("endOfServiceDate")
                      ? "ring-red-500"
                      : "ring-gray-300"
                      } placeholder:text-[#333] focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 `}
                    onChange={(date) => {
                      handleDateSelect(
                        date,
                        "endOfServiceDate"
                      );
                    }}
                    selected={parseDateString(empFormData?.endOfServiceDate)}
                    value={empFormData?.endOfServiceDate}
                    placeholderText="End of Service Date"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    preventOpenOnFocus
                    onKeyDown={(e) => handleKeyDown(e)}

                  />
                  <span
                    className={`absolute top-[55px] right-5 pointer-events-none `}
                  >
                    <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center flex flex-col justify-center h-full">
              <span className="text-center flex justify-center mb-3">
                <img
                  src="/images/no-data.png"
                  alt="Please Select any Employee"
                />
              </span>
              {selectedEmployee?.Active === false ? (<p className="text-lg font-normal text-[#333333]">
                If the user is deactivated, Please activate to edit or update their information.             </p>) : (<p className="text-lg font-normal text-[#333333]">
                  Please select any employee to see details or edit them
                </p>)}

            </div>
          )}
        </div>
        {selectedEmployee && (
          <div
            className={`flex items-center ${selectedEmployee ? "justify-between" : "justify-end"
              }`}
          >
            {selectedEmployee &&
              (selectedEmployee?.Active ? (
                <button
                  className="text-sm text-[#962D2D] py-2.5 px-3 border border-[#962D2D] rounded-lg font-semibold"
                  onClick={() =>
                    handleAddRemoveEmployee(empFormData?.userID, "deactivate")
                  }
                >
                  Deactivate Employee
                </button>
              ) : (
                <button
                  className="text-sm text-[#28833d] py-2.5 px-3 border border-[#28833d] rounded-lg font-semibold"
                  onClick={() =>
                    handleAddRemoveEmployee(empFormData?.userID, "activate")
                  }
                >
                  Activate Employee
                </button>
              ))}
            {selectedEmployee?.Active ?
              (<div className="flex items-center gap-2 justify-end">
                <button
                  className="text-sm font-semibold text-[#378EB5] border border-[#378EB5] rounded-lg px-4 py-2.5 w-full hover:bg-[#0891B2] hover:text-white"
                  onClick={() => handleDiscard()}
                >
                  Discard
                </button>
                <button
                  className={`bg-[#0891B2] text-[#FAFAFA] text-sm px-4 py-2.5 rounded-lg font-semibold  `}
                  onClick={() => handleSubmit()}
                >
                  {selectedEmployee?.ID ? "Update" : "Save"}
                </button>
              </div>) : (null)}

          </div>
        )}
      </div>
      <WorkHistory isModalOpen={isModalOpen} closeModal={closeModal} workHistoryData={workHistoryData} selectedEmployee={selectedEmployee}
        setIsModalOpen={setIsModalOpen} addPerk={addPerk} deletePerk={deletePerk} />

    </>
  );
};

export default EmployeeInformation;

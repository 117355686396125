import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import AssetStatus from "../../components/Employeestatus/AssetStatus";
import AssetTable from "../../components/Table/AssetTable";
import { fetchAllAssets, fetchAssetsInfo } from "../../services/assetRequisition.service";

const AssetRequisition = () => {
  const [assetsInfo, setAssetsInfo] = useState<any>();
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [openPopoverAssetId, setOpenPopoverAssetId] = useState<string | null>(
    null
  );
  const [isOpenMainList, setIsOpenMainList] = useState(false);
  const [totalAssestRequest, setTotalAssestRequest] = useState("")
  const getAssetsInfo = async () => {
    const res = await fetchAssetsInfo();
    if (res?.res) {
      setAssetsInfo(res?.res);
    }
  };

  useEffect(() => {
    getAssetsInfo();
  }, []);


  const getAssests = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res: any = await fetchAllAssets(startDate, endDate, searchQuery);
    if (res?.res) {
      setTotalAssestRequest(res.res.pendingCount)
      return res.res
    }
  };

  useEffect(() => {
    getAssests();

  }, [])


  return (
    <div className="basis-full px-[15px] xl:px-[30px]"
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
        openPopoverAssetId &&
          openPopoverAssetId?.length > 0 &&
          setOpenPopoverAssetId(null);
        isOpenMainList && setIsOpenMainList(false);
      }}
    >
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
      <div >
        <AssetStatus
          assetsInfo={assetsInfo}
          isOpenMainList={isOpenMainList}
          setIsOpenMainList={setIsOpenMainList}
          totalAssestRequest={totalAssestRequest}
        />
        <div className="basis-2/3">
          <AssetTable
            getAssetsInfo={getAssetsInfo}
            openPopoverId={openPopoverId}
            setOpenPopoverId={setOpenPopoverId}
            openPopoverAssetId={openPopoverAssetId}
            setOpenPopoverAssetId={setOpenPopoverAssetId}
            getAssests={getAssests}
          />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AssetRequisition;
